<template>
  <div>
    <div class="d-flex justify-content-end mt-3">
      <b-button variant="success">Load Design</b-button>

      <b-input-group style="width: 200px;" size="lg" class="ml-2">
        <b-input-group-prepend is-text>
          <i class="flaticon2-search-1"></i>
        </b-input-group-prepend>

        <b-form-input></b-form-input>
      </b-input-group>
    </div>

    <div class="table-responsive mt-4 mb-4">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr class="text-left">
            <th style="min-width: 250px" class="pl-7">
              <span class="text-dark-75">Numbering</span>
            </th>
            <th style="min-width: 100px">Title</th>
            <th style="min-width: 100px">Type</th>
            <th style="min-width: 100px">Category</th>
            <th style="min-width: 100px">scope</th>
            <th style="min-width: 101px">Aksi</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(item, i) in data.projects">
            <tr v-bind:key="i">
              <td class="pl-0">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.numbering }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.type }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.category }}
                </span>
              </td>

              <td>
                <span class="text-muted font-weight-bold d-block">
                  {{ item.scope }}
                </span>
              </td>

              <td class="pr-0">
                <a class="btn btn-light-danger font-weight-bolder font-size-sm">
                  <i class="flaticon2-trash"></i>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-end mt-5">
      <b-button variant="success">
        <i class="flaticon2-check-mark"></i>
        Simpan
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "tan",
  data() {
    return {
      data: {
        projects: [
          {
            numbering: "GEN-F08-002100",
            title: "PDPS",
            type: "Document",
            category: "External-T",
            scope: "HMI"
          }
        ]
      }
    };
  },
  methods: {
    //
  }
};
</script>
