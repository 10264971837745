<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Kode Proyek</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Kode Proyek"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Owner Proyek</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Owner"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nama Proyek</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Nama Proyek"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nomor Kontrak</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Masukkan Nomor Kontrak"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="options.statuses"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <b-tabs content-class="mt-3">
          <b-tab title="General" active>
            <general></general>
          </b-tab>

          <b-tab title="TAN">
            <tan></tan>
          </b-tab>

          <b-tab title="YK">
            <p>Coming soon !</p>
          </b-tab>

          <b-tab title="JKT">
            <p>Coming soon !</p>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Tan from "./components/Tan.vue";
import General from "./components/General.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "edit-form",
  components: {
    General,
    Tan
  },
  data() {
    return {
      filter: {
        status: null,
        name: null,
        code: null,
        owner: null,
        contractNumber: null
      },
      options: {
        statuses: [
          { value: null, text: "Pilih Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ]
      }
    };
  },
  methods: {
    //
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ubah Penyusunan" }]);
  }
};
</script>
